import {Notification} from 'element-ui'
import copyToClipboard from "copy-to-clipboard";
import router, {resetRouter} from '@/router'
import store from '@/store'
import i18n from "@/components/lang";

export const showErrorMsg = (title, msg) => {
  Notification({
    title,
    message: msg,
    type: 'error'
  })
}
export const showSuccessMsg = (title, msg, options = {}) => {
  Notification({
    title,
    message: msg,
    type: 'success',
    ...options
  })
}
export const showInfoMsg = (title, msg) => {
  Notification({
    title,
    message: msg,
    type: 'info'
  })
}
export const getToken = () => {
  return localStorage.getItem('xzd-token')
}
export const setToken = (token) => {
  localStorage.setItem('xzd-token', token)
}

export const loginSuccess = (token) => {
  setTimeout(() => {
    setToken(token)
    router.push('/')
    showSuccessMsg('提示', '登录成功')
  }, 500)
}
export const logoutSuccess = () => {
  setToken('')
  store.dispatch('user/logout')
  router.push('/login')
  resetRouter()
  showSuccessMsg('提示', '退出登录成功')
}


export const treeToList = (tree, startPid = 0, var_id = "id", var_pid = "pid", var_children = "children") => {
  tree = JSON.parse(JSON.stringify(tree))

  let someObj = {}
  someObj.list = [];
  someObj.treeToList = (tree, pid) => {
    for (let i in tree) {
      let obj = {}
      Object.keys(tree[i]).map(v => {
        if (v !== 'children') {
          obj[v] = tree[i][v]
        }
      })
      obj[var_pid] = pid
      someObj.list.push(obj)
      if (tree[i][var_children] && tree[i][var_children].length) {
        someObj.treeToList(tree[i][var_children], obj[var_id])
      }
    }
  };

  someObj.treeToList(tree, startPid)


  return someObj.list
}

export const listToTree = (list, startPid = 0, var_id = "id", var_pid = "pid", var_children = "children") => {
  list = JSON.parse(JSON.stringify(list))

  let someObj = {}
  someObj.getChildren = (pid) => {
    let children = []
    for (let i in list) {
      if (list[i][var_pid] === pid) {
        let obj = list[i]

        let c = someObj.getChildren(obj[var_id]);

        if (c.length) {
          obj[var_children] = c
        }
        children.push(obj)
      }
    }
    children = children.map((item, index) => {
      item.sort = index
      return item
    })
    return children
  }

  return someObj.getChildren(startPid)
}


export const copy = (str) => {
  try {
    copyToClipboard(str);
    showSuccessMsg(i18n.t('message.title'), i18n.t('message.copySuccess'))
  } catch (error) {
    showErrorMsg(i18n.t('message.title'), i18n.t('message.copySuccess'))
  }
}

/**
 * 文件选择
 *
 * @author zhengqiang
 * @since 2022-10-15
 */
export function chooseFile(callback, accept) {
  let input = document.createElement('input')
  input.setAttribute('type', 'file')
  if (accept) {
    input.setAttribute('accept', accept)
  }
  input.style.display = 'none'
  input.onchange = () => {
    let file = input.files[0]
    callback && callback(file)
    document.body.removeChild(input)
  }
  document.body.appendChild(input)
  input.click()
}

/**
 * 获取当前是否是全屏状态
 * @returns {boolean}
 */
export function isFullscreen() {
  return !!(
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  );
}


/**
 * 让浏览器全屏切换
 * @param el dom
 * @param fullscreen 是否全屏
 * @returns {boolean} 全屏状态
 */
export function toggleFullscreen(el, fullscreen) {
  if (!el) {
    el = document.documentElement;
  }
  if (typeof fullscreen === 'undefined' || fullscreen === null) {
    fullscreen = !isFullscreen();
  }
  if (fullscreen) {
    const rfs = (
      el.requestFullScreen ||
      el.webkitRequestFullScreen ||
      el.mozRequestFullScreen ||
      el.msRequestFullScreen
    );
    if (rfs) {
      rfs.call(el);
    } else {
      throw new Error('您的浏览器不支持全屏模式');
    }
  } else {
    const cfs = (
      document.exitFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      document.msExitFullscreen
    );
    if (cfs) {
      cfs.call(document);
    }
  }
  return fullscreen;
}