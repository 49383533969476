import request from "@/utils/request";

export function save(data) {
  return request({
    method: "post",
    url: '/system/user/save',
    data,
  });
}

export function del(data) {
  return request({
    method: "post",
    url: '/system/user/delete',
    data,
  });
}

export function setVip(data) {
  return request({
    method: "post",
    url: '/system/user/setVip',
    data,
  });
}

export function freeze(data) {
  return request({
    method: "post",
    url: '/system/user/freeze',
    data,
  });
}

export function list(data) {
  return request({
    method: "post",
    url: '/system/user/getList',
    data,
  });
}

export function detail(data) {
  return request({
    method: "get",
    url: '/system/user/detail',
    params: data,
  });
}

export function refreshKey() {
  return request({
    method: "get",
    url: '/system/user/refreshKey'
  });
}


