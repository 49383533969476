<template>
  <div class="main">
    <div class="left-side">
      <menu-box :tree="treeMenu"/>
    </div>
    <div class="right-side">
      <right-header @reloadPage="handleReloadPage"/>
      <Tabs/>
      <el-scrollbar class="content">
        <div class="inner" :key="pageKey">
          <keep-alive>
            <router-view class="route"/>
          </keep-alive>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import treeMenu from "@/layout/components/TreeMenu";
import Tabs from "./components/Tabs";
import MenuBox from "@/layout/components/menu/MenuBox";
import RightHeader from "@/layout/components/RightHeader";

export default {
  components: {RightHeader, MenuBox, Tabs, treeMenu},
  name: "BaseLayout",
  
  data() {
    return {
      pageKey: Math.random()
    }
  },
  
  computed: {
    treeMenu() {
      return this.$store.state.user.menu;
    }
  },
  
  methods: {
    handleReloadPage() {
      this.pageKey = Math.random()
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/var.scss";

.main {
  display: flex;
  height: 100vh;
  overflow: hidden;
  
  .right-side {
    flex: 1;
    width: 0;
    background: #f0f2f5;
  }
  
  .content {
    height: calc(100vh - 100px);
    
    ::v-deep {
      .el-scrollbar__wrap .el-scrollbar__view {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        
        > .inner {
          height: 0;
          flex: 1;
          padding: 15px;
          display: flex;
          flex-direction: column;
          
          > .route {
            background-color: #ffffff;
            height: 0;
            flex: 1;
            padding: 20px;
          }
        }
      }
    }
  }
}
</style>