import {getLoginUserInfo} from "@/api/common";
import BaseLayout from "@/layout/BaseLayout";
import EmptyLayout from "@/layout/EmptyLayout";
import Page404 from "@/views/common/Page404";
import markMap from "@/router/markMap";
import {listToTree} from "@/utils/func";

const user = {
  namespaced: true,
  state: {
    info: null,
    tree: null,

    routes: [],
    menu: [],
    marks: [],
    homePath: '',
  },
  mutations: {
    SET(state, [k, v]) {
      state[k] = v;
    },
  },
  actions: {
    getLoginUserInfo(context) {
      return getLoginUserInfo({}).then((res) => {

        let { myResourceList } = res.data

        let tree = listToTree(myResourceList, 0);

        context.commit('SET', ['info', res.data.info || {} ])
        context.commit('SET', ['myResourceList', myResourceList])
        context.commit('SET', ['tree', tree])

        let {routes, menu, marks, homePath} = getInfoFromTree(tree)


        context.commit('SET', ['routes', routes])
        context.commit('SET', ['menu', menu])
        context.commit('SET', ['marks', marks])
        context.commit('SET', ['homePath', homePath])
        return {routes, menu, marks}
      })
    },

    logout({commit}) {
      return new Promise(resolve => {
        commit('SET', ['info', null])
        commit('SET', ['myResourceList', []])
        commit('SET', ['tree', null])
        commit('SET', ['routes', []])
        commit('SET', ['menu', []])
        commit('SET', ['marks', []])
        commit('SET', ['homePath', ''])
        resolve()
      })
    },
  },

  // return this.$store.getters['user/getLoginUserInfo']
  // getters: {
  //   getLoginUserInfo(state) {
  //     let v = state.loginUserInfo
  //     if (v === null) {
  //       vuex.dispatch('user/getLoginUserInfo');
  //     }
  //     return v;
  //   },
  // }
}


function getInfoFromTree(tree) {
  let someObj = {}
  someObj.getRoutesFromTree = (list) => {
    let newList = [];
    list.forEach(v => {
      if (['dir'].includes(v.type)) {
        newList.push({
          path: v.mark,
          name: v.mark,
          component: EmptyLayout,
          children: someObj.getRoutesFromTree(v.children || []),
          meta: {title: v.name, enTitle:v.enName},
        })
      }

      if (['menu'].includes(v.type)) {
        newList.push({
          path: v.mark,
          name: v.mark,
          component: markMap[v.mark] || Page404,
          meta: {title: v.name, enTitle:v.enName},

        })
      }
    })
    return newList
  }

  someObj.homePath = ''
  someObj.getMenuFromTree = (list, fatherPath = '') => {
    let newList = [];
    list.forEach(v => {
      if (['dir'].includes(v.type)) {
        newList.push({
          path: fatherPath + '/' + v.mark,
          name: v.name,
          enName: v.enName,
          icon: '',
          children: someObj.getMenuFromTree(v.children || [], fatherPath + '/' + v.mark),
        })
      }

      if (['menu'].includes(v.type)) {
        newList.push({
          path: fatherPath + '/' + v.mark,
          name: v.name,
          enName: v.enName,
          icon: '',
        })

        if (someObj.homePath === '') {
          someObj.homePath = fatherPath + '/' + v.mark
        }
      }

    })
    return newList
  }

  someObj.marks = []
  someObj.getMarksFromTree = (list) => {
    list.forEach(v => {
      someObj.marks.push(v.mark)
      if (v.children) {
        someObj.getMarksFromTree(v.children)
      }
    })
  }
  someObj.getMarksFromTree(tree)


  let menu = someObj.getMenuFromTree(tree)

  return {
    menu: menu,
    marks: someObj.marks,
    homePath: someObj.homePath,

    routes: {
      path: '/',
      name: 'index',
      component: BaseLayout,
      children: someObj.getRoutesFromTree(tree),
      redirect: someObj.homePath,
    },
  }
}

export default user


