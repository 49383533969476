<template>
  <div>
    <template v-for="(item,index) in tree">
      <el-submenu
        v-if="item.children && item.children.length"
        popper-class="menu-box-popper"
        :index="item.path">
        <template slot="title">
          <i v-if="item.icon" :class="item.icon"/>
          <i class="el-icon-folder-opened" v-else/>
          <span slot="title" v-if="collapse === false">
            <span v-if="$i18n.locale === 'en'">{{item.enName}}</span>
            <span v-else>{{item.name}}</span>
          </span>
        </template>
        <menu-son v-bind="$attrs" :tree="item.children"/>
      </el-submenu>
      <el-menu-item v-else @click="clickItem(item)" :index="item.path">
        <i v-if="item.icon" :class="item.icon"></i>
        <i class="el-icon-tickets" v-else></i>
        <span slot="title">
          <span v-if="$i18n.locale === 'en'">{{item.enName}}</span>
          <span v-else>{{item.name}}</span>
        </span>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
export default {
  name: "MenuSon",
  props: {
    tree: {
      type: Array,
      default: function () {
        return []
      }
    },
    collapse: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    clickItem(item) {
      this.$router.push(item.path)
    }
  }
}
</script>



