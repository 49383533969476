<template>
  <el-dialog
    :title="$t('layout.profile')"
    :visible.sync="showDialog"
    destroy-on-close
    width="800px">
    <el-descriptions :column="1" border :labelStyle="{width: '100px'}">
      <el-descriptions-item :label="$t('user.account')">{{userInfo.account}}</el-descriptions-item>
      <el-descriptions-item :label="$t('user.name')">{{userInfo.name}}</el-descriptions-item>
      <el-descriptions-item :label="$t('user.realName')">{{userInfo.realName}}</el-descriptions-item>
      <el-descriptions-item :label="$t('user.apiKey')">
        <show-space>
          <div>{{userInfo.apiKey || '-'}}</div>
          <el-button type="text" size="mini" :disabled="!userInfo.apiKey" @click="handleCopyKey">{{$t('user.copy')}}</el-button>
          <el-button type="text" size="mini" :loading="loading" @click="handleRefreshKey">{{$t('user.generateKey')}}</el-button>
        </show-space>
      </el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>
<script>
import {refreshKey} from '@/api/user'
import {copy} from "@/utils/func";

export default {
  name: "ProfileInfoDialog",
  
  data() {
    return {
      showDialog: false,
      loading: false
    }
  },
  
  computed: {
    userInfo() {
      return this.$store.state.user.info || {}
    }
  },
  
  methods: {
    start() {
      this.showDialog = true
    },
    
    handleCopyKey() {
      copy(this.userInfo.apiKey)
    },
  
    handleRefreshKey() {
      this.loading = true
      refreshKey().then(() => {
        this.$store.dispatch('user/getLoginUserInfo')
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    }
  }
}
</script>