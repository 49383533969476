const path = require('path')

function titleCase(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

let files = require.context('./markMaps', false, /\.js$/)

const modules = []
files.keys().forEach(key => {
  const moduleName = path.basename(key, '.js')
  const ModuleName = titleCase(moduleName)
  modules[ModuleName + 'List'] = files(key).default[ModuleName + 'List']
})

export default modules
