import {toggleFullscreen} from "@/utils/func";

const setting = {
  namespaced: true,
  state: {
    menuCollapse: false,
    fullscreen: false,
  },

  mutations: {
    SET(state, [k, v]) {
      state[k] = v;
    }
  },

  actions: {
    toggleMenuCollapse({commit, state}) {
      commit('SET', ['menuCollapse', !state.menuCollapse])
    },
    toggleFullscreen({commit}) {
      let fullscreen = toggleFullscreen()
      commit('SET', ['fullscreen', fullscreen])
    },
  }
}


export default setting


