import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "@/components/lang";

import '@/assets/style/index.scss'

import '@/utils/directive'

import ElementUI from 'element-ui';
Vue.use(ElementUI);

import Editor from "@/components/Editor";
Vue.component('Editor', Editor)

import '@/components/index'

import '@/assets/icons/index'

Vue.config.productionTip = false

// todo... 环境配置

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

