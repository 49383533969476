module.exports = {
  button: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    refresh: 'Refresh',
    save: 'Save',
    search: 'Search',
    reset: 'Reset',
    saveSortAfterDrag: 'Save sort after drag',
    confirm: 'Confirm',
    cancel: 'Cancel',
    fullscreen: 'Fullscreen',
    cancelFullscreen: 'Un fullscreen',
    exportExcel: 'Export Excel',
    importExcel: 'Import Excel',
    downloadExcelTemplate: 'Download Excel Template',
  },
  placeholder: {
    input: 'input',
    select: 'select'
  },
  language: {
    zh: '中文',
    en: 'English'
  },
  message: {
    title: 'Tips',
    saveSuccess: 'Save Success',
    addSuccess: 'Add Success',
    editSuccess: 'Edit Success',
    deleteSuccess: 'Delete Success',
    copySuccess: 'Copy Success',
    operateSuccess: 'Success',
    downloadFail: 'Download Error:',
    stepOne: 'First Step',
    stepTow: 'Second Step',
    chooseExcel: 'Choose Excel',
    moduleNameNotSet: '要上报的模块名称尚未设置',
    importing: 'Importing Excel',
    getLoginUserInfoFail: 'Getting info of user error !',
    requestError: 'Request Error',
    systemError: 'System Error',
    invalidToken: 'Invalid Token',
    codeNotExists: 'Code Not Exists',
    serverError: 'Server Error',
    serverResponseError: 'Server Response Error',
    deleteNoData: 'Delete No Data',
  },
  confirm: {
    delete: 'Delete ?',
    exportExcel: 'Export Excel ?',
    deleteBatch: 'Delete ?',
  },
  layout: {
    changePassword: 'changePassword',
    logout: 'logout',
    oldPassword: 'oldPassword',
    oldPasswordPlaceholder: 'oldPasswordPlaceholder',
    oldPasswordError: 'oldPasswordError',
    newPassword: 'newPassword',
    newPasswordPlaceholder: 'newPasswordPlaceholder',
    newPasswordError: 'newPasswordError',
    confirmPassword: 'confirmPassword',
    confirmPasswordPlaceholder: 'confirmPasswordPlaceholder',
    confirmPasswordError: 'confirmPasswordError',
    changePasswordSuccess: 'changePasswordSuccess',
    providesTechnicalSupport:'XZD Provides Technical Support',
    profile: 'profile',
    home: 'Home',
    closeLeft: 'Close Left',
    closeRight: 'Close Right',
    closeOther: 'Close Other',
    closeAll: 'Close All'
  },
  listPage: {
    reload: 'reload',
    fullscreen: 'fullscreen',
    size: {
      title: 'Size',
      large: 'large',
      medium: 'default',
      small: 'medium',
      mini: 'small'
    }
  },
  login: {
    title: 'Login Page',
    account: 'account',
    password: 'password',
    nickName: 'nickName',
    accountPlaceholder: 'accountPlaceholder',
    passwordPlaceholder: 'passwordPlaceholder',
    loginBtn:'Login Btn'
  },
  page404: {
    goHome: 'goHome'
  },
  common: {
    id: 'id',
    operate: 'operate'
  },
  company: {
    name: 'name',
    concat: 'concat',
    mobile: 'mobile',
    maxEmployeeCount: 'maxEmployeeCount',
    employeePageCount: 'employeePageCount'
  },
  employee: {
    company: 'company',
    account: 'account',
    password: 'password',
    name: 'name',
    realName: 'realName',
    sort: 'sort',
  },
  page: {
    name: 'name',
    edit: 'edit',
    preview: 'preview',
    back: 'back',
    fitWindow: 'fitWindow',
    fitView: 'fitView',
    normalSize: 'normalSize'
  },
  resource: {
    type: 'type',
    name: 'name',
    mark: 'mark',
    enabled: 'enabled',
    disabled: 'disabled',
    sort: 'sort',
    dir: 'dir',
    menu: 'menu',
    btn: 'btn',
    func: 'func',
    link: 'link'
  },
  role: {
    name: 'name',
    description: 'description',
    sort: 'sort',
    setResource: 'setResource'
  },
  user: {
    account: 'account',
    password: 'password',
    name: 'name',
    realName: 'realName',
    sort: 'sort',
    type: 'type',
    company: 'company',
    apiKey: 'API KEY',
    copy: 'Copy',
    generateKey: 'Generate Key'
  },
  product: {
    name: 'product name',
    attr: 'product attrs',
    attrType: 'attr type',
    attrMark: 'attr mark',
    attrName: 'attr name',
    addFromProductStandard: 'Add from standard product',
    attrMarkError1: 'attr mark can not be empty for no.{}',
    attrMarkError2: 'attr mark can just be number、letter、underline, and can\'t start with number for no.{}',
    attrNameError: 'attr name can not be empty for no.{}',
    standardProduct: 'standard product',
    key: 'product key'
  },
  device: {
    product: 'product',
    enName: 'english name',
    zhName: 'mark name',
    status: 'status',
    inactive: 'inactive',
    offline: 'offline',
    online: 'online',
    mqttConnectParams: 'MQTT Connect Params',
    mqttTopics: 'MQTT topics',
    viewLog: 'View log',
    viewData: 'View data',
    time: 'time',
    topic: 'topic',
    desc: 'description',
    type: 'type',
    oneHour: '1hour',
    oneDay: '24hours',
    sevenDays: '7days',
    custom: 'custom',
    startTime: 'start time',
    endTime: 'end time',
    to: 'to',
    content: 'content',
    dataType: 'data type',
    updateTime: 'update time',
    newestValue: 'newest value',
    charts: 'charts',
    table: 'table',
    rawValue: 'raw value',
    deviceData: 'device data',
    timeRange: 'time range',
    copy: 'copy',
    key: 'device key'
  },
  video: {
    name: 'name',
    videoStreamUrl: 'video stream url',
    viewVideo: 'View video'
  },
  deviceGroup: {
    name: 'group name',
    forwardStatus: 'forward status',
    forwardType: 'forward type',
    forwardParams: 'forward params',
    forwardTemplate: 'forward template',
    enabled: 'enabled',
    disabled: 'disabled',
    addDevice: 'add device',
    viewDevice: 'view device',
    kafka: {
      server: 'server ip and port',
      keySerializer: 'key serializer',
      valueSerializer: 'value serializer',
      topic: 'topic',
      bufferMemory: 'buffer memory',
      compressionType: 'compression type',
      batchSize: 'batch size',
      lingerMs: 'linger ms',
      acks: 'acks',
      retries: 'retries',
      requestTimeoutMs: 'request timeout Mms'
    }
  }
}