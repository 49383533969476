import request from "@/utils/request";

export function login(data){
  return request({
    method: "post",
    url: '/system/login/start',
    data
  })
}


export function getLoginUserInfo(data) {
  return request({
    method: "post",
    url: '/system/loginUser/getMyInfo',
    data
  })
}

export function uploadFile(file) {
  let data = new FormData()
  data.append('file', file)
  return request({
    method: "post",
    url: '/system/common/upload',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
