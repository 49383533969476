export default {
  tokenWhiteList: [
    'Login',
    'Page404'
  ],
  loginPath:'/login',
  tokenKey:'token',
  apiHost:'https://yanhe.dev.xinzhidi.cn/api',
  // apiHost:'http://localhost:7205',
  appName:'烟盒回收后台',
  appEnName:'Cigarette Box Recycling',
}