import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

// 引入各个语言配置文件
import zh from './zh'
import en from './en'

Vue.use(VueI18n)

// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: localStorage.getItem('locale') || 'zh', // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    zh: {
      ...zh,
      ...zhLocale
    },
    en: {
      ...en,
      ...enLocale
    }
  }
})

Vue.prototype.$ts = function(...keys) {
  return keys.map(key => i18n.t(key)).join('')
}

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})


export default i18n