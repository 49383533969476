module.exports = {
  button: {
    add: '新增',
    edit: '编辑',
    delete: '删除',
    refresh: '刷新',
    save: '保存',
    search: '查询',
    reset: '重置',
    saveSortAfterDrag: '保存拖拽后的顺序',
    confirm: '确定',
    cancel: '取消',
    fullscreen: '全屏',
    cancelFullscreen: '取消全屏',
    exportExcel: '导出Excel',
    importExcel: '导入Excel',
    downloadExcelTemplate: '下载Excel模板',
  },
  placeholder: {
    input: '请输入',
    select: '请选择'
  },
  language: {
    zh: '中文',
    en: 'English'
  },
  message: {
    title: '提示',
    saveSuccess: '保存成功',
    addSuccess: '添加成功',
    editSuccess: '编辑成功',
    deleteSuccess: '删除成功',
    copySuccess: '复制成功',
    operateSuccess: '操作成功',
    downloadFail: '下载出错:',
    stepOne: '第1步',
    stepTow: '第2步',
    chooseExcel: '将填写好的Excel模版选中',
    moduleNameNotSet: '要上报的模块名称尚未设置',
    importing: '导入..',
    getLoginUserInfoFail: '获取登录者信息失败!',
    requestError: '请求异常',
    systemError: '系统错误',
    invalidToken: '登录令牌失效(V2)',
    codeNotExists: 'code码不存在',
    serverError: '服务器异常',
    serverResponseError: '服务器响应异常',
    deleteNoData: '请选择要删除的数据',
  },
  confirm: {
    delete: '确定删除吗？',
    exportExcel: '确定导出搜索到的数据到Excel, 是否继续?',
    deleteBatch: '确定删除，是否继续？',
  },
  layout: {
    changePassword: '修改密码',
    logout: '退出登录',
    oldPassword: '原密码',
    oldPasswordPlaceholder: '请输入原密码',
    oldPasswordError: '原密码错误',
    newPassword: '新密码',
    newPasswordPlaceholder: '请输入新密码',
    newPasswordError: '新密码不能与原密码一致',
    confirmPassword: '确认密码',
    confirmPasswordPlaceholder: '请输入确认密码',
    confirmPasswordError: '两次输入密码不一致',
    changePasswordSuccess: '修改密码成功，请重新登录',
    providesTechnicalSupport:'XZD 提供技术支持',
    profile: '个人信息',
    home: '主页',
    closeLeft: '关闭左侧',
    closeRight: '关闭右侧',
    closeOther: '关闭其他',
    closeAll: '关闭所有'
  },
  listPage: {
    reload: '刷新',
    fullscreen: '全屏',
    size: {
      title: '密度',
      large: '宽松',
      medium: '默认',
      small: '中等',
      mini: '紧凑'
    }
  },
  login: {
    title: '登录',
    account: '账号',
    password: '密码',
    nickName: '昵称',
    accountPlaceholder: '请输入账号',
    passwordPlaceholder: '请输入密码',
    loginBtn:'登录'
  },
  page404: {
    goHome: '回到首页'
  },
  common: {
    id: '主键',
    operate: '操作'
  },
  resource: {
    type: '类型',
    name: '资源名称',
    mark: '资源标识',
    enabled: '启用',
    disabled: '不启用',
    sort: '排序(升序)',
    dir: '菜单(含子项)',
    menu: '菜单(不含子项)',
    btn: '按钮',
    func: '功能',
    link: '超链接'
  },
  role: {
    name: '名称',
    description: '描述',
    sort: '排序(升序)',
    setResource: '设置权限'
  },
  user: {
    account: '账号',
    password: '密码',
    name: '用户昵称',
    realName: '真实姓名',
    sort: '排序(升序)',
    type: '用户类型',
    company: '所属公司',
    apiKey: '接口KEY',
    copy: '复制',
    createTime: '注册时间',
    startTime: '开始时间',
    endTime: '结束时间',
    isFreeze: '是否冻结',
    isVip: '是否Vip用户',
    setVip: '设置为Vip用户',
    freezeUser: '冻结用户',
    freezeDays: '冻结天数',
    pleaseChooseUser: '请选择用户',
    confirmSetUserVip: '确定要将选中的用户设置为Vip码？',
  },
  order: {
    orderNum: '订单编号',
    status: '订单状态',
    statusSettled: '已结算',
    statusUnSettled: '待结算',
    phone: '手机号',
    passRate: '有效率',
    total: '总金额',
    createTime: '创建时间',
    startTime: '开始时间',
    endTime: '结束时间',
    downloadOrderCodeBtn: '下载筛选订单包含的码',
    uploadOrderCodeResultBtn: '回传收码结果',
    settleOrder: '结算订单',
    freezeUser: '冻结用户',
    paymentType: '收款方式',
    paymentTypeWechat: '微信',
    paymentTypeAli: '支付宝',
    paymentName: '收款姓名',
    paymentCode: '收款二维码',
    codeContent: '码内容',
    price: '价格',
    codeStatus: '状态',
    viewCode: '查看二维码',
    confirmSettled: '确认已付款'
  },
  article: {
    type: '类型',
    sort: '排序(升序)',
    title: '标题',
    content: '内容',
    teach: '新手教程'
  },
  price: {
    name: '名称',
    logo: '图片',
    smallPrice: '烟盒价格',
    smallCode: '烟盒条码',
    bigPrice: '条盒价格',
    bigCode: '条盒条码',
    sort: '排序(升序)',
    importPrice: '导入价格',
  }
}