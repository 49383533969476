<template>
  <span style="text-align: left">
    <el-button type="text"  size="mini" :loading="exportExcelLoading" @click="start">导出Excel</el-button>
  </span>
</template>

<script>
import {showErrorMsg} from "@/utils/func";

export default {
  name: "exportExcelButton",
  props: ['queryParam', 'getListFunc'],
  data() {
    return {
      exportExcelLoading: false, // 是否正在导出excel
    }
  },
  methods: {
    start(){
      this.$confirm('确定导出搜索到的数据到Excel, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.exportExcel()
      }).catch(() => {

      });
    },
    exportExcel() {
      this.exportExcelLoading = true
      this.getListFunc({...this.queryParam, exportExcel: true}).then(res => {
        console.log(res)
        let blob = res.data;
        let fileName = res.headers['xzd-file-name']

        this.downloadFile(blob, fileName)
      }).catch(err => {
        showErrorMsg('提示', '下载出错:'+err.message)
      }).finally(() => {
        setTimeout(() => {
          this.exportExcelLoading = false
        }, 500)
      })
    },

    downloadFile(blob, fileName) {
      // 通过 URL.createObjectURL(Blob对象), 可以把 Blob对象 转换成一个链接地址,该地址可以直接用在某些 DOM 的 src 或者 href 上
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = decodeURIComponent(fileName);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    }
  }
}
</script>

<style scoped>

</style>