<template>
  <div class="ele-admin-tabs">
    <el-tabs :value="$route.name" @tab-click="onTabClick" @tab-remove="onTabRemove">
      <el-tab-pane
        v-for="d in tabs"
        :key="d.name"
        :name="d.name"
        :label="$i18n.locale === 'en' ? d.enTitle : d.title"
        closable
      />
    </el-tabs>
    <el-dropdown class="ele-admin-tabs-drop" @command="onDropClick">
      <i class="el-icon-arrow-down"></i>
      <el-dropdown-menu slot="dropdown">
        <slot name="dropdown">
          <el-dropdown-item command="left" icon="el-icon-back">{{ $t('layout.closeLeft') }}</el-dropdown-item>
          <el-dropdown-item command="right" icon="el-icon-right">{{ $t('layout.closeRight') }}</el-dropdown-item>
          <el-dropdown-item command="other" icon="el-icon-close">{{ $t('layout.closeOther') }}</el-dropdown-item>
          <el-dropdown-item command="all" icon="el-icon-circle-close">{{ $t('layout.closeAll') }}</el-dropdown-item>
        </slot>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>

export default {
  
  data() {
    return {
      tabs: []
    }
  },
  
  computed: {
    routes() {
      return this.$store.state.user.routes.children
    }
  },
  
  watch: {
    $route() {
      this.addTabs()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  
  mounted() {
    this.addTabs()
  },
  
  methods: {
    onTabClick({name}) {
      if (name === this.$route.name) {
        return
      }
      this.tabs.forEach(tab => {
        if (tab.name === name) {
          this.$router.push(tab.path)
        }
      })
    },
    
    onTabRemove(name) {
      this.tabs = this.tabs.filter(tab => tab.name !== name)
      if (this.tabs.length) {
        if (this.$route.name === name) {
          this.$router.push(this.tabs.slice(-1)[0].path)
        }
      } else {
        this.$router.push('/')
      }
    },
    
    onDropClick(command) {
      switch (command) {
        case 'left':
          this.tabs = this.tabs.slice(this.tabs.findIndex(tab => tab.name === this.$route.name))
          break;
        case 'right':
          this.tabs = this.tabs.slice(0, this.tabs.findIndex(tab => tab.name === this.$route.name) + 1)
          break;
        case 'other':
          this.tabs = this.tabs.filter(tab => tab.name === this.$route.name)
          break;
        case 'all':
          this.tabs = []
          this.$router.push('/')
          break;
      }
    },
    
    addTabs() {
      const {meta} = this.$route
      if (meta?.title) {
        if (this.tabs.find(tab => tab.name === this.$route.name)) {
          return
        }
        
        let {name, path, meta: {title, enTitle}} = this.$route
        this.tabs.push({
          name,
          path,
          title,
          enTitle
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/var.scss";

/* 多页签 */
.ele-admin-tabs {
  height: $--tabs-height;
  background: $--color-white;
  box-shadow: $--header-light-shadow;
  padding-right: $--tabs-height;
  z-index: $--layout-z-index;
  box-sizing: border-box;
  position: relative;
  
  .el-tabs__item {
    height: $--tabs-height;
    line-height: $--tabs-height;
    color: $--color-text-secondary;
    transition: background-color .2s, color .2s;
    padding: 0 15px !important;
    
    &.is-closable {
      padding-right: 8px !important;
    }
    
    &:after {
      content: "";
      width: 0;
      height: 2px;
      background: $--color-primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    
    &:hover {
      color: $--color-text-primary;
      background: $--header-tool-hover-bg;
    }
    
    &.is-active {
      color: $--color-primary;
      background: $--menu-item-hover-fill;
      
      &:after {
        width: 100%;
      }
    }
    
    .el-icon-close {
      font-size: 13px;
      margin-left: 4px;
      color: $--color-text-secondary;
      
      &:before {
        transform: none;
        display: inline;
      }
      
      &:hover {
        color: #fff;
        background: $--color-danger;
      }
    }
    
    &:focus.is-active.is-focus:not(:active) {
      box-shadow: 0 0 0 2px $--color-primary inset;
      border-radius: 0;
    }
  }
  
  .el-tabs__active-bar,
  .el-tabs__content {
    display: none;
  }
  
  @mixin tabs-btn {
    width: $--tabs-height;
    height: $--tabs-height;
    line-height: $--tabs-height;
    color: $--color-text-secondary;
    font-size: $--font-size-medium;
    transition: background-color .2s, color .2s;
    box-sizing: border-box;
    text-align: center;
    
    &:hover {
      color: $--color-text-primary;
      background: $--header-tool-hover-bg;
    }
  }
  
  .el-tabs__nav-wrap {
    padding: 0 $--tabs-height;
    
    &:before,
    &:after {
      @include tabs-btn;
      content: "\e6de";
      position: absolute;
      top: 0;
      left: 0;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      font-family: 'element-icons' !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      cursor: not-allowed;
    }
    
    &:after {
      right: 0;
      left: auto;
      bottom: auto;
      height: auto;
      background: none;
      content: "\e6e0";
    }
    
    &.is-scrollable {
      padding: 0 ($--tabs-height - 1px) 0 $--tabs-height;
      
      &:before,
      &:after {
        display: none;
      }
    }
  }
  
  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    @include tabs-btn;
    
    i {
      vertical-align: -1px;
    }
  }
  
  .ele-admin-tabs-drop {
    position: absolute;
    right: 0;
    top: 0;
    
    .el-icon-arrow-down {
      @include tabs-btn;
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>
